<template>
  <e-register-form
    ref="customerForm"
    :person-form="person"
    :is-edit="isEdit"
    :is-read-only="isReadOnly"
    is-customer
    @clean-person-data="$emit('clean-person-data')"
  >
    <template #others-inputs>
      <b-row>
        <b-col md="6">
          <FormulateInput
            v-if="!isReadOnly && $can('UpdateCustomerClassification', 'Customer')"
            id="customer-classification"
            v-model="person.customerClassificationId"
            :label="$t('Classificação')"
            :placeholder="$t('Selecione')"
            class="required"
            validation="required"
            type="vue-select"
            :clearable="false"
            :options="customerClassificationsOptions"
          />
          <FormulateInput
            v-else
            id="customer-classification"
            v-model="person.customerClassificationId"
            :label="$t('Classificação')"
            type="label"
            :options="customerClassificationsOptions"
          />
        </b-col>
        <b-col md="6">
          <FormulateInput
            v-if="!isReadOnly && $can('UpdatePriceTable', 'Customer')"
            id="price-table"
            v-model="person.priceTableId"
            :label="$t('Tabela de preços')"
            :placeholder="$t('Selecione')"
            type="select-with-button"
            :options="getComboPriceTables"
            :button-permission="$can('Create', 'PriceTable')"
            @click="$emit('show-price-table')"
          />
          <FormulateInput
            v-else
            id="price-table"
            v-model="person.priceTableId"
            :label="$t('Tabela de preços')"
            type="label"
            :options="getComboPriceTables"
          />
        </b-col>
        <b-col md="12">
          <FormulateInput
            v-if="isReadOnly"
            id="observation"
            v-model="person.observation"
            type="label"
            :label="$t('Observação')"
          />
          <FormulateInput
            v-else
            id="observation"
            v-model="person.observation"
            type="textarea"
            :label="$t('Observação')"
          />
        </b-col>
        <b-col md="4">
          <div v-if="isReadOnly">
            <p class="h6">
              {{ $t('Mensagem Push?') }}
            </p>
            <e-status-badge
              style="max-width: 80px"
              :status="!!person.pushNotification"
              type="yesNo"
            />
          </div>
          <FormulateInput
            v-else
            id="customer_form-push_message"
            v-model="person.pushNotification"
            type="switch"
            :label="$t('Mensagem Push?')"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="customer_form-active_online"
            v-model="person.activeOnline"
            :type="isReadOnly ? 'status-badge' : 'switch'"
            :label="$t('Ativo online?')"
            :instruction="
              $t('Quando habilitado, o cliente está apto a comprar através dos canais online')
            "
          />
        </b-col>
      </b-row>
    </template>
  </e-register-form>
</template>

<script>
import ERegisterForm from '@/views/components/forms/ERegisterForm.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import EStatusBadge from '@/views/components/EStatusBadge.vue'

export default {
  components: {
    BRow,
    BCol,
    ERegisterForm,
    EStatusBadge,
  },
  /**
   * events:
   * clean-person-data
   * show-price-table
   */
  props: {
    person: {
      type: Object,
      required: true,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('pages/sale/clients', ['customerClassificationsOptions']),
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
  },

  methods: {
    validate() {
      return this.$refs.customerForm.validate()
    },
  },
}
</script>

<style></style>
